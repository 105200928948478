import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";
import { useBannerStore } from "./promo";
import { useCategoryStore } from "./category";
import { useProductStore } from "./product";
import { useChatStore } from "./chat";
import { useAdvantageStore } from "@/store/advantage";

export const useAppStore = defineStore("app", () => {
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;
	const token = ref(useLocalStorage("token"));

	const visited = ref(false);
	const visitedWelcomePage = ref(useLocalStorage("visitedWelcomePage", false));
	const visitedLanguagePage = ref(useLocalStorage("visitedLanguagePage", false));
	const showSideBar = ref(false);
	const isFetching = ref(true);
	const payment_methods = ref([]);
	const product_sale = ref([]);
	const product_recommend = ref([]);
	const product_new = ref([]);
	const product_top = ref([]);

	function updateVisited(value) {
		visited.value = value;
	}

	function updateVisitedWelcomePage(value) {
		visitedWelcomePage.value = value;
	}

	function updateVisitedLanguagePage(value) {
		visitedLanguagePage.value = value;
	}

	async function getPaymentMethod() {
		var res = await axios
			.get(baseUrl + "payment-methods", configs)
			.then((res) => res.data);
		payment_methods.value = res;
	}

	async function getAppData() {
		const sBanner = useBannerStore();
		const sCategory = useCategoryStore();
		const sProduct = useProductStore();
		const sChat = useChatStore();
		const sAdvantage = useAdvantageStore();

		var res = await axios
			.get(
				baseUrl + "app-data" + (token.value ? "?token=" + token.value : ""),
				configs
			)
			.then((res) => res.data);

		[
			sAdvantage.datas,
			sCategory.categories,
			sBanner.bannersData,
			product_sale.value,
			product_recommend.value,
			product_new.value,
			product_top.value,
			sProduct.productsLiked,
			sChat.numberUnreadChats,
		] = res.data;

		isFetching.value = false;

		return res.data;
	}

	return {
		visited,
		visitedWelcomePage,
		visitedLanguagePage,
		showSideBar,
		payment_methods,

		product_sale,
		product_recommend,
		product_new,
		product_top,

		isFetching,

		updateVisited,
		updateVisitedWelcomePage,
		updateVisitedLanguagePage,
		getPaymentMethod,
		getAppData,
	};
});
