const mainRoutes = [
	// {
	// 	path: "/",
	// 	name: "main",
	// 	component: () => import("@/views/pages/main/Index.vue"),
	// },
	{
		path: "/",
		name: "main",
		component: () => import("@/views/pages/main/components/Language.vue"),
		// meta: { requiresAuth: false },
	},
	{
		path: "/main-advantage1",
		name: "main-advantage1",
		component: () => import("@/views/pages/main/components/Advantage2.vue"),
	},
	{
		path: "/main-advantage2",
		name: "main-advantage2",
		component: () => import("@/views/pages/main/components/Advantage.vue"),
	},

	{
		path: "/choose-language",
		name: "choose-language",
		component: () => import("@/views/pages/main/components/Language.vue"),
		// meta: { requiresAuth: false },
	},

	{
		path: "/support/:id",
		name: "support-detail",
		component: () => import("@/views/pages/support/show.vue"),
	},

	{
		path: "/balasan-komplain",
		name: "balasan-komplain",
		component: () => import("@/views/pages/complain/balasan-complain.vue"),
	},
	{
		path: "/pusat-bantuan",
		name: "pusat-bantuan",
		component: () => import("@/views/pages/bantuan/index.vue"),
	},
	{
		path: "/pusat-bantuan/pertanyaan",
		name: "pusat-bantuan/pertanyaan",
		component: () =>
			import("@/views/pages/bantuan/partials/detail-pertanyaan.vue"),
	},
	{
		path: "/pusat-bantuan/pertanyaan2",
		name: "pusat-bantuan/pertanyaan2",
		component: () =>
			import("@/views/pages/bantuan/partials/detail-pertanyaan-2.vue"),
	},
	{
		path: "/pusat-bantuan/kategori",
		name: "pusat-bantuan/kategori",
		component: () => import("@/views/pages/bantuan/partials/detail-kategori.vue"),
	},
	{
		path: "/syarat-kebijakan",
		name: "syarat-kebijakan",
		component: () => import("@/views/pages/syarat-kebijakan/index.vue"),
	},
];

export default mainRoutes;
