import axios from "axios";
import { createRouter, createWebHistory } from "vue-router";
import authRoutes from "./modules/authRoutes";
import mainRoutes from "./modules/mainRoutes";
import homeRoutes from "./modules/homeRoutes";
import orderRoutes from "./modules/orderRoutes";
import menuRoutes from "./modules/menuRoutes";
import cartRoutes from "./modules/cartRoutes";
import chatRoutes from "./modules/chatRoutes";
import Swal from "sweetalert2";

const notFound = () => import("../views/pages/404/Index.vue");

const partnerScreen = () => import("../views/pages/rekanan/Index.vue");

const partnerDetail = () =>
	import("../views/pages/rekanan/components/rekanan-detail.vue");

const likeScreen = () => import("../views/pages/disukai/Index.vue");

const likeList = () =>
	import("../views/pages/disukai/components/disukai-list.vue");

const likeCollectionList = () =>
	import("@/views/pages/disukai/components/disukai-list-collection.vue");

const detailProduk = () => import("../views/pages/detail-produk/Index.vue");
// Rekomendasi
const rekomendasiScreen = () => import("../views/pages/rekomendasi/Index.vue");

// produk seller
const produkSellerScreen = () =>
	import("@/views/pages/produk-seller/Index.vue");

// sale
const saleScreen = () => import("../views/pages/sale-product/Index.vue");

const topScreen = () => import("../views/pages/top-product/Index.vue");

// last-viewed-product
const lastViewedScreen = () =>
	import("../views/pages/last-viewed-product/Index.vue");

// drink
const drinkScreen = () => import("../views/pages/drink-product/Index.vue");
const newestScreen = () => import("../views/pages/newest_products/Index.vue");

const routes = [
	// Start Screen
	...mainRoutes,
	// End of Start Screen

	//region auth routes
	...authRoutes,
	//end of region auth routes

	//region home routes
	...homeRoutes,
	//end of region home routes

	//region menu routes
	...menuRoutes,
	//end of region menu routes

	//region riwayat routes
	...orderRoutes,
	//end of region riwayat routes

	//region cart routes
	...cartRoutes,
	//end of region cart routes

	//region chat routes
	...chatRoutes,
	//end of region chat routes

	{
		path: "/rekanan",
		name: "rekanan",
		component: partnerScreen,
		// meta: { requiresAuth: true },
	},

	{
		path: "/rekanan/:id",
		name: "rekanan-detail",
		component: partnerDetail,
		props: true,
		// meta: { requiresAuth: true },
	},

	{
		path: "/disukai",
		name: "disukai",
		component: likeScreen,
		meta: { requiresAuth: true },
	},

	{
		path: "/disukai-list",
		name: "disukai-list",
		component: likeList,
		meta: { requiresAuth: true },
	},
	{
		path: "/disukai-collection/:id",
		name: "disukai-collection",
		component: likeCollectionList,
		meta: { requiresAuth: true },
	},

	{
		path: "/detail/:id",
		name: "detail",
		component: detailProduk,
		// meta: { requiresAuth: true },
	},

	{
		path: "/:pathmath(.*)*",
		name: "notFound",
		component: notFound,
		// meta: { requiresAuth: true },
	},

	//Rekomendasi
	{
		path: "/rekomendasi",
		name: "rekomendasi",
		component: rekomendasiScreen,
		// meta: { requiresAuth: true },
	},

	//Produk Seller
	{
		path: "/produk-seller",
		name: "produk-seller",
		component: produkSellerScreen,
		// meta: { requiresAuth: true },
	},

	//End of Rekomendasi

	// sale
	{
		path: "/sale",
		name: "sale",
		component: saleScreen,
		// meta: { requiresAuth: true },
	},
	// top product
	{
		path: "/top-product",
		name: "top-product",
		component: topScreen,
		// meta: { requiresAuth: true },
	},

	// last viewed product
	{
		path: "/last-viewed-product",
		name: "last-viewed-product",
		component: lastViewedScreen,
		meta: { requiresAuth: true },
	},

	// drink
	{
		path: "/drinks",
		name: "drinks",
		component: drinkScreen,
		// meta: { requiresAuth: true },
	},

	{
		path: "/newest_products",
		name: "newest_products",
		component: newestScreen,
		// meta: { requiresAuth: true },
	},

	// TESTING
	// {
	// 	path: "/testing",
	// 	name: "home_testing",
	// 	component: HomeView,
	// },
	// {
	// 	path: "/testing/about",
	// 	name: "about_testing",
	// 	component: AboutView,
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	// component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

const checkToken = () => {
	var token = localStorage.getItem("token");
	if (token && token == "undefined") localStorage.setItem("token", "");
};

router.beforeEach(async (to, from) => {
	checkToken();
	var token = localStorage.getItem("token");
	const isAuth = token ? true : false;

	if (isAuth) {
		var res = await axios
			.get(process.env.VUE_APP_API_URL_LOCAL + "user/profile?token=" + token, {
				headers: {
					apiKey: process.env.VUE_APP_API,
				},
			})
			.then((res) => res.data)
			.catch((err) => err.response);

		if (res.data?.data?.refresh_token)
			localStorage.setItem("token", res.data?.data?.refresh_token);
		else if (res.success == true) {
			if (_.includes(["/login", "/register"], to.fullPath)) {
				return {
					path: "/home",
				};
			}
		} else {
			localStorage.clear();
			await Swal.fire({
				toast: true,
				position: "top",
				showConfirmButton: false,
				timer: 1500,
				timerProgressBar: true,
				icon: "info",
				title: "Session Expired, Please Login Again",
			});
			return {
				path: "/login",
			};
		}
	} else if (to.meta.requiresAuth) {
		localStorage.clear();
		return {
			path: "/login",
		};
	}
});

export default router;
